import React from 'react'
import { RouteViewComponent, routeView } from "..";

const NotFound: RouteViewComponent<{}> = () => {
  return (
    <>This page cannot be found</>
  );
}

NotFound.displayName = 'NotFound'

export default routeView(NotFound)