import React from "react";
import VideoPage from '../../../components/VideoPage';
import { RouteViewComponent, routeView } from "../..";

const ComingFromGoingTo: RouteViewComponent<{}> = () => {
  const videoSources = [
    {
      src: `${process.env.PUBLIC_URL}/assets/videos/coming-from-going-to.mp4`,
      type: "video/mp4"
    }
  ]

  return (
    <VideoPage 
      name="ComingFromGoingTo"
      sources={videoSources}
    />
  );
}

ComingFromGoingTo.displayName = "ComingFromGoingTo"

export default routeView(ComingFromGoingTo)