const data = [
  {
    year: 2019,
    description: "<b>CTV News Vancouver.</b> “Diversity in Vancouver's Electronic Music Scene”.",
    link: "https://bc.ctvnews.ca/video?clipId=1735094",
  }, {
    year: 2019,
    description: "<b>National Post.</b> “CURRENT showcases feminist electronic arts and artists” by Stuart Derdeyn.",
    link: "https://nationalpost.com/entertainment/festivals/current-showcases-feminist-electronic-arts-and-artists",
  }, {
    year: 2019,
    description: "<b>Radio Canadá Internacional.</b> “Artistas digitales feministas, mujeres y personas no binarias, convergen esta semana en Vancouver” por Paloma Martínez.",
    link: "https://www.rcinet.ca/es/2019/07/23/artistas-digitales-feministas-mujeres-y-personas-no-binarias-convergen-esta-semana-en-vancouver/",
  }, {
    year: 2019,
    description: "<b>SAD Magazine.</b> “The World We Want To Exist In: Creating Culture With the Current Feminist Electronic Arts Symposium” by Jacqueline Salomé.",
    link: "https://www.sadmag.ca/blog/2019/7/23/the-world-we-want-to-exist-in-creating-culture-with-the-current-feminist-electronic-arts-symposiumnbsp",
  }, {
    year: 2018,
    description: "<b>Discorder Magazine.</b> “CURRENT 2.0” by Fatemeh Ghayedi. July 2018 Edition.",
    link: "https://www.citr.ca/discorder/summer-2018/current-2-0/",
  }, {
    year: 2018,
    description: "<b>Exclaim.</b> “The CURRENT Symposium Dreams of a New Future for Electronic Art in Vancouver and Beyond” by Josiah Hughes.",
    link: "https://exclaim.ca/music/article/the_current_symposium_dreams_of_a_new_future_in_vancouver_and_beyond",
  }, {
    year: 2018,
    description: "<b>Red Bull.</b> “Dream up a new future for Vancouver’s art scene with CURRENT” by Jamila Pomeroy.",
    link: "https://www.redbull.com/ca-en/current-symposium-vancouver-2018",
  }, {
    year: 2017,
    description: "<b>The Georgia Straight.</b> “Women Power Up The Current With Electonic Arts Festival” by Kate Wilson. July 2017 Edition. Print and online.",
    link: "https://www.straight.com/music/941081/women-power-current-electronic-arts-and-music-festival",
  }, {
    year: 2017,
    description: "<b>Discorder Magazine.</b> “CURRENT” by Clara Dubber. July 2017 Edition.",
    link: "https://www.citr.ca/discorder/summer-2017/current/",
  }, {
    year: 2016,
    description: "<b>Resident Advisor.</b> “Vancouver, Rhythms of the Canadian Riviera” by Andrew Ryce.",
    link: "https://www.residentadvisor.net/features/2740",
  }, {
    year: 2016,
    description: "<b>The Fader.</b> “4 Canadian DYI Media Collectives You Should Know” by Anupa Mistry.",
    link: "https://www.thefader.com/2016/05/13/canadian-media-collectives-women",
  }, {
    year: 2015,
    description: "<b>Noisey.</b> “Genero Sound is Adding a Feminine Thread to The Electronic Music Tapestry” by Maya-Roisin Slater.",
    link: "https://noisey.vice.com/en_ca/article/genero-sound-vancouver-electronic-interview-2015",
  }, {
    year: 2015,
    description: "<b>Scout Magazine.</b> “Weaving Musical Threads With Soledad Munoz of Local Label Genero” by Grady Mitchell.",
    link: "https://scoutmagazine.ca/2015/08/14/vancouverites-weaving-musical-threads-with-soledad-munoz-of-local-label-genero",
  }, {
    year: 2015,
    description: "<b>Megaphone Magazine.</b> “Challenging the Binary: Soledad Munoz helms a new record label for women” by Aurora Tejeida. Print.",
  }, {
    year: 2015,
    description: "<b>Beatroute.</b> “Genero a Space for Female Artist and Musicians to Callaborate in the Sound Realm” by Hannah Tollefson.",
    link: "http://www.beatroute.ca/2015/02/02/genero-a-space-for-female-artists-and-musicians-to-collaborate-in-the-sound-realm/",
  }, {
    year: 2015,
    description: "<b>Discorder Magazine.</b> “Homegrown Labels: Genero” by Kristian Voveris. May 2015 Edition. Print.",
    link: "https://www.citr.ca/discorder/april-2015/homegrown-labels-2/",
  }, {
    year: 2015,
    description: "<b>Vancouver Weekly.</b> “Soledad Munoz: An Artist in Between” by Daniel Robichaud.",
    link: "https://vancouverweekly.com/soledad-munoz-artist/",
  }, {
    year: 2014,
    description: "<b>The Mainlander.</b> “Genero Sound: Yes/No Conversations Keep Us From Failed Utopias” by Steffanie Ling.",
    link: "http://themainlander.com/2014/09/21/arts-culture-genero-sound-yes-no-conversations-keep-us-from-false-utopias/",
  }
]

export default data