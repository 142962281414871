import { CVSection } from ".";

const data: CVSection[] = [
  { 
    sectionName: "Education",
    entries: [{
      description: "Master of Fine Arts in Studio Art - <b>School of the Art Institute of Chicago, Chicago, IL</b>",
      year: 2019,
    },{
      description: "Bachelor in Fine Arts - <b>Emily Carr University of Arts and Design, Vancouver, BC</b>",
      year: 2013,
    },{
      description: "Textile Art Diploma - <b>Capilano University, Vancouver, BC</b>",
      year: 2011,
    },{
      description: "Film Studies - <b>Universidad de Artes y Ciencias Sociales, Santiago, Chile</b>",
      year: 2007
    }] 
  },
  { 
    sectionName: "Exhibitions + Performances",
    entries: [{
      description: "<b>Boundless Encounters.</b> Hangzhou Triennial of Fiber Arts. Hangzhou, China",
      year: 2019,
    },{
      description: "<b>MFA Shows.</b> School of the Arts Institute of Chicago. Chicago, IL, USA",
      year: 2019,
    },{
      description: "<b>Gather.</b> Comfort Station. Chicago, IL, USA",
      year: 2019,
    },{
      description: "<b>SER SUR.</b> Chuquimarca Projects. Chicago, IL, USA",
      year: 2019,
    },{
      description: "<b>TONE.</b> FIELD Contemporary. Vancouver, BC, Canada",
      year: 2018,
    },{
      description: "<b>Waveforms.</b> Experimental Sound Studio. Chicago, IL, USA",
      year: 2018,
    },{
      description: "<b>Tie It Off & Count Again.</b> Hamilton Audio Visual Node. Hamilton, ON, Canada",
      year: 2018,
    },{
      description: "<b>Phulkari.</b> The Duderstadt Video Studio, University of Michigan. Ann Arbor, MI, USA",
      year: 2018,
    },{
      description: "<b>SSSSHHHH.</b> School of the Arts Institute of Chicago. Chicago, IL, USA",
      year: 2018,
    },{
      description: "<b>Genero at FUSE.</b> Vancouver Art Gallery. Vancouver, BC, Canada",
      year: 2017,
    },{
      description: "<b>Sounds at Sunset.</b> Western Front. Vancouver, BC, Canada",
      year: 2017,
    },{
      description: "<b>In Response:.</b> Artspeak Gallery. Vancouver, BC, Canada",
      year: 2017,
    },{
      description: "<b>Period Pieces (une rétrospective de Sunset Terrace).</b> Project Pangee. Montreal, QC, Canada",
      year: 2017,
    },{
      description: "<b>Moving Out Series.</b> RECESS. Portland, OR, USA",
      year: 2015,
    },{
      description: "<b>New Forms Festival.</b> Science World. Vancouver, BC, Canada",
      year: 2014,
    },{
      description: "<b>ECU Award Winners Exhibition.</b> Winsor Gallery. Vancouver, BC, Canada",
      year: 2013,
    },{
      description: "<b>Strait Lines + Disrupting Currents.</b> Haida Gwaii Museum. Skidegate, BC, Canada",
      year: 2013,
    }] 
  },
  { 
    sectionName: "Curatorial Projects",
    entries: [{
      description: "<b>CURRENT: Feminist Electronic Art Symposium - Co-Founder.</b> Multidisciplinary, intersectional, music and electronic Art symposium working with women and non-binary artists.",
      year: 2017,
    },{
      description: "<b>Genero: Music Label and Sound Project - Founder.</b> Audio project and label founded in 2014, which promotes the works of women and non-binary artist in the sound realm.",
      year: 2014,
    },{
      description: "<b>Sounds at Sunset - Founder.</b> Series of solo experimental electronic music performances and sound/art explorations.",
      year: 2014,
    }] 
  },
  { 
    sectionName: "Awards, Scholarships and Grants",
    entries: [{
      description: "<b>Amplify BC Music Industry Initiatives</b> - “CURRENT: Feminist Electronic Art Symposium”",
      year: 2019,
    },{
      description: "<b>City of Vancouver Civic Engagement Grant</b> - “CURRENT: Feminist Electronic Art Symposium”",
      year: 2018,
    },{
      description: "<b>City of Vancouver Community Arts Grant</b> - “CURRENT: Feminist Electronic Art Symposium”",
      year: 2017,
    },{
      description: "<b>New Artist Society Full Merit Scholarship</b> - School of the Art Institute of Chicago",
      year: 2017,
    },{
      description: "<b>Emily Carr President’s Media Award</b> - Installation/Interactive Media",
      year: 2013,
    },{
      description: "<b>City of Vancouver Mayor’s Arts Award for Emerging Artist</b> - Craft and Design",
      year: 2012,
    },{
      description: "<b>Emiko Masuhara Carley Scholarship</b>",
      year: 2011,
    },{
      description: "<b>Capilano Textile Arts Program Award</b>",
      year: 2009,
    }]
  }
]

export default data