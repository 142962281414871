import React from 'react'
import { RouteViewComponent, routeView } from "..";
import data from './data';

const Bio: RouteViewComponent<{}> = () => {
  return (
    <>
      { data.map( (para, index) => <p key={index}>{para}</p> ) }
    </>
  )
}

Bio.displayName = 'Bio'

export default routeView(Bio)