import React, { useState } from 'react';
import './styles.css';
import Header from '../Header';
import Content from '../Content';
import { BrowserRouter as Router } from 'react-router-dom'
import menuItems from './menuItems';

const App: React.FC = () => {
  const [timeoutInstance, setTimeoutInstance] = useState()
  const [mouseRecentlyMoved, setMouseRecentlyMoved] = useState(false)

  const MOUSE_TIMEOUT_DELAY = 4000

  const mouseTimeout = setTimeout(() => {
    setMouseRecentlyMoved(false)
    setTimeoutInstance(undefined)
  }, MOUSE_TIMEOUT_DELAY)
  
  const setMouseMove = () => {
    clearTimeout(timeoutInstance)
    setTimeoutInstance(mouseTimeout)
    setMouseRecentlyMoved(true)
  }

  return (
    <Router>
      <div className="App" onMouseMove={setMouseMove}>
        <Header 
          menuItems={menuItems}
          mouseRecentlyMoved={mouseRecentlyMoved}/>
        <Content />
      </div>
    </Router>
  );
}

export default App;
