import React from "react";
import VideoPage from "../../components/VideoPage";

const homePageData: JSX.Element[] = [
  <VideoPage 
    key="DetenidxsDesaparecidxs" 
    name="Detenidxs-Desaparecidxs" 
    sources={[{
      src: `${process.env.PUBLIC_URL}/assets/videos/detenidxs-desaparecidxs.mp4`, 
      type: "video/mp4"
    }]}/>,

  <VideoPage 
    key="Once" 
    name="Once" 
    sources={[{
      src: `${process.env.PUBLIC_URL}/assets/videos/once.mp4`, 
      type: "video/mp4"
    }]}/>,
    
  <VideoPage 
    key="ComingFromGoingTo" 
    name="ComingFromGoingTo" 
    sources={[{
      src: `${process.env.PUBLIC_URL}/assets/videos/coming-from-going-to.mp4`, 
      type: "video/mp4"
    }]}/>,
]

export default homePageData