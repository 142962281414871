import React from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import "./styles.css"
import { 
  Home,
  Curatorial,
  Bio,
  CV,
  Press,
  NotFound,
} from "../../views";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { DetenidosDesarecidos1, DetenidosDesarecidos2, Once, SonicWeavings, ComingFromGoingTo } from "../../views/Works";

const AppRouter: React.FunctionComponent<RouteComponentProps> = ({location}) => {
  return (
    <TransitionGroup 
      component="section"
      className={'Router'} 
    >
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={500}
      >
        <Switch location={location}>
          <Route component={Home} path="/" exact />

          <Route component={WorksRouter} path="/works" />

          <Route component={Curatorial} path="/curatorial" />

          <Route component={CV} path="/cv" />

          <Route component={Bio} path="/bio" />

          <Route component={Press} path="/press" />

          <Route component={NotFound} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

const WorksRouter: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <>
      <Route 
        component={DetenidosDesarecidos2}
        path={`${match.path}/detenidxs-desaparecidxs`} />

      <Route 
        component={Once}
        path={`${match.path}/once`} />

      <Route 
        component={ComingFromGoingTo}
        path={`${match.path}/coming-from-going-to`} />
    </>
  );
}

export default withRouter(AppRouter)