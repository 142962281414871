import { MenuItem } from "../Menu"

const worksItems = [
  {name: "La Parte De Atras De La Arpillera", path: "https://lapartedeatras.com/", outbound: true},
  {name: "Detenidxs Desaparecidxs", path: "/works/detenidxs-desaparecidxs"},
  {name: "Once Del Dieciocho", path: "/works/once"},
  {name: "coming from | going to", path: "/works/coming-from-going-to"},
]

const menuItems: MenuItem[] = [
  {name: "Works", path: "/works", dropdown: worksItems},
  {name: "Curatorial", path: "/curatorial"},
  {name: "Bio", path: "/bio"},
  {name: "CV", path: "/cv"},
  {name: "Press", path: "/press"},
]

export default menuItems