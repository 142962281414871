const loaderID = "ga-load"
const scriptID = "ga-script"

function gaLoader() {
  let analyticsLoader = document.createElement("script")
  analyticsLoader.id = loaderID
  analyticsLoader.async = true
  analyticsLoader.src = "https://www.googletagmanager.com/gtag/js?id=UA-79086802-3" 

  return analyticsLoader
}

function gaScript() {
  let analyticsScript = document.createElement("script")
  analyticsScript.id = scriptID
  analyticsScript.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    
    gtag('config', 'UA-79086802-3');
  `

  return analyticsScript
}

function gaScriptsPresent() {
  return document.getElementById(loaderID) && document.getElementById(scriptID)
}

function prependGAToHead() {
  document.head.prepend(gaLoader(), gaScript())
}

function injectAnalytics() {  
  if (!gaScriptsPresent()) { prependGAToHead() }
}

export default injectAnalytics