import React, { useEffect, useState } from 'react'
import './styles.css'
import * as CONSTANTS from '../../lib/constants';
import Menu, { MenuProps, MenuItem } from '../Menu';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import ColorSelector from '../../lib/colorSelector';

interface HeaderProps {
  menuItems: MenuItem[]
  mouseRecentlyMoved: boolean
}

const Header: React.FunctionComponent<HeaderProps & RouteComponentProps> = ({menuItems, location, mouseRecentlyMoved}) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false)

  const onResize = (event: Event) => {
    const eventWindow = event.target as Window
    setIsMobileScreen(determineIsMobileScreen(eventWindow))
  }

  useEffect(() => {
    setIsMobileScreen(determineIsMobileScreen(window))
    window.addEventListener("resize", onResize);

    return function cleanup() {
      window.removeEventListener("resize", onResize);
    };
  })

  return (
    <header 
      className={determineHeaderClassName(location.pathname, isMobileScreen, mouseRecentlyMoved)}
      style={{backgroundColor: ColorSelector.for(location.pathname).background}}
    >
      <HomeIcon 
        pathname={location.pathname} />
      <Menu
        isMobileScreen={isMobileScreen} 
        menuItems={menuItems} />
    </header>
  )
}

interface HomeIconProps {
  pathname: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({pathname}) => {
  return (
    <Link 
      to="/" 
      className="HeaderTitle"
      style={{ color: ColorSelector.for(pathname).foreground }}
    >
      <h1>{CONSTANTS.WEBSITE_NAME}</h1>
    </Link>
  );
}

const determineHeaderClassName = (pathname: string, isMobileScreen: boolean, mouseRecentlyMoved: boolean) => {
  let headerClassName = "Header"

  if (pathname === "/" || pathname.includes("works")) { 
    headerClassName = headerClassName + " onVideoPage" 
  }
  
  if (isMobileScreen) {
    headerClassName = headerClassName + " Mobile" 
  } else if (mouseRecentlyMoved) { 
    headerClassName = headerClassName + " mouseMoved" 
  }

  return headerClassName
}

const determineIsMobileScreen = (window: Window) => {
  return window.innerWidth < CONSTANTS.MOBILE_BREAKPOINT
}

export default withRouter(Header)