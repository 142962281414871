import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import './styles.css';
import MobileMenu from "./Mobile";
import MainMenu from "./main";
import ColorSelector from "../../lib/colorSelector";
import { RouteComponentProps, withRouter } from "react-router";

interface MenuContainer {
  isMobileScreen: boolean;
}

export interface MenuProps {
  menuItems: MenuItem[];
}

export interface MenuItem {
  name: string;
  path: string;
  outbound?: boolean;
  dropdown?: MenuItem[];
}

export interface MenuItemColor {
  pathname: string
}

export interface MenuHandler {
  setActive: Dispatch<SetStateAction<boolean>>
}

interface ContactMenuItemProps {
  mobile: boolean
}

export const ContactMenuItem: React.FunctionComponent<ContactMenuItemProps & MenuItemColor> = ({pathname, mobile}) => {
  return (
    <a 
      key={"Contact"} 
      className={mobile ? "Mobile MenuItemLink" : "MainMenuItemLink"}
      href='mailto:soledadfatimamunoz@gmail.com' 
      target="_blank" 
      rel="noopener noreferrer"
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <h3 className={mobile ? 'Mobile MenuItem' : 'MainMenuItem'}>
        Contact
      </h3>
    </a>
  );
}

const Menu: React.FunctionComponent<MenuContainer & MenuProps & RouteComponentProps> = ({location, isMobileScreen, menuItems}) => {
  return (
    isMobileScreen ? 
      <MobileMenu pathname={location.pathname} menuItems={menuItems} /> 
      : 
      <MainMenu pathname={location.pathname} menuItems={menuItems} />
  );
}

export default withRouter(Menu)
