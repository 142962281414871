import React, { Fragment } from 'react';
import './styles.css'
import { RouteViewComponent, routeView } from "..";
import data from './data'

const Press: RouteViewComponent<{}> = () => {
  return (
    <>
      <table>        
        <thead>
          <tr>
            <th colSpan={2} className={"PressFieldHeader"}>
              <h3>Selected Press</h3>
            </th>
          </tr>
        </thead>
        <tbody className="PressFieldEntries">
          {data.map(generatePressEntry)}
        </tbody>
      </table>
    </>
  );
}

Press.displayName = 'Press'

interface PressEntry {
  year: number,
  description: string,
  link?: string,
}

function generatePressEntry(entry: PressEntry, index: number) {
  return(
    <Fragment key={generateBase64Key(JSON.stringify(entry))}>
      <tr className="PressEntrySpacer"> 
      </tr>
      <tr className="PressEntry"> 
        <td className="PressEntryYear">{entry.year}</td>
        <td>
          <a 
            className="PressEntryLink"
            href={entry.link} 
            target="_blank" 
            rel="noopener noreferrer"
            dangerouslySetInnerHTML={{__html: entry.description}}
          />
        </td>
      </tr>
    </Fragment>
  )
}

function generateBase64Key(uniqueString: string) {
  return window.btoa(unescape(encodeURIComponent(uniqueString)))
}

export default routeView(Press)