import React from 'react';
import { MenuItem, MenuHandler, MenuItemColor } from '..';
import { Link } from 'react-router-dom';
import './styles.css'
import ColorSelector from '../../../lib/colorSelector';

interface DropdownProps {
  mobile: boolean
  dropdown: MenuItem[]
}

const DropdownMenu: React.FC<MenuHandler & DropdownProps & MenuItemColor> = ({mobile, dropdown, setActive, pathname}) => {
  
  return (
    <div 
      className={mobile ? "MainMenuItemDropdown Mobile" : "MainMenuItemDropdown"} 
      onMouseLeave={() => {if (!mobile) setActive(false)}}
    >
      {
        dropdown
          .map((props) => ({...props, setActive, pathname}))
          .map(DropdownMenuItem)
      }
      <div className="DropdownBackground"></div>
    </div>
  );
}

const DropdownMenuItem: React.FunctionComponent<MenuItem & MenuHandler & MenuItemColor> = ({path, name, setActive, pathname, outbound }, index) => {
  const className = index === 0 ? "DropdownItemLink first" : "DropdownItemLink"

  if (outbound) {
    return <a 
      key={index} 
      href={path} 
      className={className}
      onClick={() => setActive(false)}
      target="_blank" 
      rel="noopener noreferrer"
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <h3 style={{whiteSpace: "pre-wrap"}} className="DropdownItem">
        {name}
      </h3>
    </a>
  } else {
    return  <Link 
      key={index} 
      to={path} 
      className={className}
      onClick={() => setActive(false)}
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <h3 style={{whiteSpace: "pre-wrap"}} className="DropdownItem">
        {name}
      </h3>
    </Link>
  }
}

export default DropdownMenu