interface ColorScheme {
  foreground: string;
  background: string;
}

class ColorSelector {
  private static defaultColorScheme: ColorScheme = {
    foreground: "inherit",
    background: "inherit"
  }

  private static colorForPath: { [key: string]: ColorScheme } = {
    "/": {
      foreground: "inherit",
      background: "inherit"
    },
    "/works/detenidxs-desaparecidxs": {
      foreground: "inherit",
      background: "inherit"
    },
    "/works/once": {
      foreground: "inherit",
      background: "inherit"
    },
    "/works/sonic-weavings": {
      foreground: "inherit",
      background: "inherit"
    },
    "/works/coming-from-going-to": {
      foreground: "inherit",
      background: "inherit"
    }
  }

  private static definedForPath(pathname: string) {
    return ColorSelector.colorForPath.hasOwnProperty(pathname)
  }

  public static for(pathname: string): ColorScheme {
    return ColorSelector.definedForPath(pathname) ? ColorSelector.colorForPath[pathname] : ColorSelector.defaultColorScheme
  }
}

export default ColorSelector