import React from 'react';
import { CuratorialItemProps } from "./item";

const data: CuratorialItemProps[] = [
  {
    link: "https://www.currentsymposium.com",
    image: `${process.env.PUBLIC_URL}/assets/photos/curatorial/current.jpg`,
    content: (
      <p>
        CURRENT is a multidisciplinary, intersectional, music and electronic art symposium working with women and non-binary artists in Vancouver, BC and beyond. The goal of this symposium is to foster and disseminate feminist content through the cross-pollination of ideas, and intergenerational knowledge sharing. By offering free, public, all ages panels and accessible workshops, we wish to cultivate growth within the local community, and create a more equal landscape within the growing Electronic Arts ecology.
      </p>
    ),
  },{
    link: "https://genero.bandcamp.com/",
    image: `${process.env.PUBLIC_URL}/assets/photos/curatorial/genero.jpg`,
    content: (
      <p>
        Genero is a feminist audio project started in 2014, which promotes the works of women and non-binary artist in the sound realm. It has released five albums and hosted several showcases since.
      </p>
    ),
  },{
    link: "http://vandocument.com/2014/08/sounds-under-the-stars/",
    image: `${process.env.PUBLIC_URL}/assets/photos/curatorial/sounds-at-sunset.jpg`,
    content: (
      <p>
        Sounds at Sunset was a series of solo experimental electronic music performances and sound/art explorations presented at Sunset Terrace.
      </p>
    ),
  },{
    link: "http://vandocument.com/2013/09/coming-from-going-to-a-night-of-film-and-sound/",
    image: `${process.env.PUBLIC_URL}/assets/photos/curatorial/coming-from-going-to.jpg`,
    content: (
      <>
        <p>
          Coming From | Going To was a project co-curated with Katrina Niebergal of the screening five 8mm rolls, filmed between 1957 and 1960, of a single family’s immigration from Tokyo to Peru and Chile. This found footage was projected and accompanied by sounds works made by eleven artists.
        </p>
        <p>
          Participating artists: Sam Beatch, Michael Cook, Jessica Chau, Emma Lamorte, Soledad Muñoz, Ronan Nanning-Watson, Katrina Niebergal, Daniel Rincon, Jonathan Scherk, Logan Sturrock
        </p>
      </>
    ),
  },
]

export default data