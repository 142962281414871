import React from 'react';

export interface CuratorialItemProps {
  link: string;
  image: string;
  content: JSX.Element
}

const CuratorialItem: React.FunctionComponent<CuratorialItemProps> = ({link, image, content}, key) => {
  return (
    <div 
      key={key}
      className={"CuratorialItem"}
      >
      <a 
        className={"CuratorialItemLink"}
        href={link} 
        target="_blank" 
        rel="noopener noreferrer"
        >
        <img 
          className={"CuratorialItemImage"}
          src={image} 
          />
      </a>
      <div className={"CuratorialItemContent"} >
        {content}
      </div>
    </div>
  );
}

export default CuratorialItem