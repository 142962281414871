import React from "react";
import VideoPage from '../../../components/VideoPage';
import { RouteViewComponent, routeView } from "../..";

const DetenidosDesarecidos2:RouteViewComponent<{}> = () => {
  const videoSources = [
    {
      src: `${process.env.PUBLIC_URL}/assets/videos/detenidos-desarecidos-2.mp4`,
      type: "video/mp4"
    }
  ]

  return (
    <>
    <div style={{height: "60vh", width: "100%"}}>

    <iframe src="https://player.vimeo.com/video/533299516" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    </div>
    <p>“Destenidxs Desaparecidxs” is a sound piece composed of woven copper wire portraits of the people who were disappeared by Augusto Pinochet’s dictatorship in Chile (1973-1991). The first iteration of this work was exhibited at the Sullivan Galleries, located in the Northeast corner of the Louis Sullivan building (point zero of the city of Chicago) forty-five years after the military coup d'état in Chile. With this work, I was hoping to unveil the oppressive relationship between this city—specifically Milton Friedman and the Department of Economics at the University of Chicago—and impoverished Chilean communities throughout time. By exploring copper wire’s conductive properties and its historical materiality, the piece intends to bring attention to the politics behind the indiscriminate extraction of natural resources and the human lives lost due to Neoliberalism. </p>
    <p>The soundscape created by Detenidxs Desaparecidxs is achieved by using copper wire weavings as coils or vibration pickups. The larger portraits are woven with a double weave technique that organizes the warp’s cotton threads into two attached layers forming a copper wire coil. A group of these “portrait-coils” operate as inductors connected to a circuit that captures the gallery's electromagnetic fields, while the others function as coil antennas connected to a transmitter. The smaller pieces are made with a single-layer weave technique, creating a somewhat uniform copper surface that acts as a vibration pick-up. Some of these smaller works are connected to a patch on SuperCollider where I can live sample the signal or sculpt the speed and pitch with granular synthesis. By giving each portrait a different size, source, and sound, I wish to provide as many voices as possible. Each individual "disappeared" in my piece acts as a filter of its environment, making a unified sonic landscape that memorializes their previously silenced gesture.</p>
    </>
  );
}

DetenidosDesarecidos2.displayName = "DetenidosDesarecidos2"

export default routeView(DetenidosDesarecidos2)