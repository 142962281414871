import React, { Fragment } from 'react'
import { RouteViewComponent, routeView } from "..";
import './styles.css'
import data from './data';

export interface CVEntry {
  description: string;
  year: number
  endYear?: number
}

export interface CVSection {
  sectionName: string;
  entries: CVEntry[]
}

const CV: RouteViewComponent<{}> = () => {
  return (
    data ? 
      <>
        {generateCvTable(data)}
      </>
      :
      null
  )
}

CV.displayName = 'CV'

export default routeView(CV)

function generateCvTable(data: CVSection[]) {
  return (
    <table className="CVTable">
      {generateCvFragments(data)}
    </table>
  )
}

function generateCvFragments(data: CVSection[]) {
  return data.map(generateCvFragment)
}

function generateCvFragment(cvSection: CVSection, index: number) {
  return (
    <Fragment key={index}>
      {generateFieldCvHeader(cvSection, index)}
      {generateFieldCvEntries(cvSection, index)}
    </Fragment>
  )
}

function generateFieldCvHeader(cvSection: CVSection, index: number) {
  return (
    <thead>
      <tr>
        <th colSpan={2} className={index === 0 ? "CVFieldHeader first" : "CVFieldHeader"}>
          <h3>{cvSection.sectionName}</h3>
        </th>
      </tr>
    </thead>
  );
}

function generateFieldCvEntries(cvSection: CVSection, index: number) {
  return (
    <tbody className="CVFieldEntries">
      {cvSection.entries.map(generateCvEntry)}
    </tbody>
  )
}

function generateCvEntry(entry: CVEntry, index: number) {
  return(
    <tr className="CVEntry" key={index}> 
      <td className="Year">{entry.year}</td>
      <td dangerouslySetInnerHTML={{__html: entry.description}}></td>
    </tr>
  )
}