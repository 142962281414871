import React from "react";
import VideoPage from '../../../components/VideoPage';
import { RouteViewComponent, routeView } from "../..";

const Once: RouteViewComponent<{}> = () => {
  const videoSources = [
    {
      src: `${process.env.PUBLIC_URL}/assets/videos/once.mp4`,
      type: "video/mp4"
    }
  ]

  return (
    <VideoPage 
      name="Once"
      sources={videoSources}
    />
  );
}

Once.displayName = "Once"

export default routeView(Once)