import React from "react";
import './styles.css'
import Home from "./Home";
import CV from "./CV";
import NotFound from "./NotFound";
import Bio from "./Bio";
import Press from './Press';
import Curatorial from './Curatorial';
import { RouteComponentProps } from 'react-router';
import { ComingFromGoingTo, DetenidosDesarecidos1, DetenidosDesarecidos2, Once, SonicWeavings } from "./Works";

export type RouteViewComponent<T> = React.FunctionComponent<T & RouteComponentProps>

export function routeView<T>(View: React.ComponentType<T>): React.ComponentType<T & RouteComponentProps> {
  return (props) => (
    <article className={`RouteView ${View.displayName}`}>
      <View {...props}/>
    </article>
  )
}

export {
  Curatorial,
  Bio,
  CV,
  Home,
  NotFound,
  Press,
  ComingFromGoingTo,
  DetenidosDesarecidos1,
  DetenidosDesarecidos2,
  Once,
  SonicWeavings,
}