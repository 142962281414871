import React, { useState } from 'react';
import { MenuProps, MenuItem, ContactMenuItem, MenuItemColor } from '.';
import { Link } from 'react-router-dom';
import DropdownMenu from './Dropdown';
import ColorSelector from '../../lib/colorSelector';

const MainMenu: React.FunctionComponent<MenuProps & MenuItemColor> = ({menuItems, pathname}) => {
  return (
    <nav className="MainMenu">
      {
        menuItems
          .map((props) => ({ ...props, pathname}))
          .map(MainMenuItem)
          .concat(<ContactMenuItem key={'Contact'} mobile={false} pathname={pathname} />)
      }  
    </nav>
  );
}

const MainMenuItem: React.FunctionComponent<MenuItem & MenuItemColor> = (props) => {
  return (
    !props.dropdown ? 
      <Link 
        key={props.name}
        to={props.path} 
        className="MainMenuItemLink"
        style={{color: ColorSelector.for(props.pathname).foreground}}
      >
        <h3 className="MainMenuItem">
          {props.name}
        </h3>
      </Link>
      :
      <MenuDropdownItem key={props.name} {...props} />
  );
}

const MenuDropdownItem: React.FunctionComponent<MenuItem & MenuItemColor> = ({name, dropdown, pathname}) => {
  const [isActive, setActive] = useState(false)
  return (
    <a
      key={name}
      className="MainMenuItemLink" 
      onMouseEnter={() => setActive(true)} 
      onMouseLeave={() => setActive(false)}
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <h3 className="MainMenuItem">{name}</h3>
      {
        isActive && dropdown ? 
          <DropdownMenu 
            mobile={false}
            pathname={pathname} 
            dropdown={dropdown} 
            setActive={setActive}/>
          :
          null
      }
    </a>
  );
}



export default MainMenu