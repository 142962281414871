import React from "react";
import "./styles.css";
import AppRouter from "../AppRouter";

const Content: React.FC = () => {
  return (
    <main className={'Content'}>
      <AppRouter />
    </main>
  )
}

export default Content