import React, { useState, Dispatch, SetStateAction } from 'react';
import "./styles.css"
import { MenuProps, MenuItem, MenuHandler, ContactMenuItem, MenuItemColor } from "..";
import hamburgerMenu from "./assets/hamburger-menu.svg";
import { Link } from 'react-router-dom';
import ColorSelector from '../../../lib/colorSelector';
import DropdownMenu from '../Dropdown';

const MobileMenu: React.FunctionComponent<MenuProps & MenuItemColor> = ({pathname, menuItems}) => {
  const [isActive, setActive] = useState(false)
  
  return ( 
    <div className="MobileMenuContainer"> 
      <div 
        className="MobileMenuIcon"
        onClick={() => setActive(!isActive)} 
      >
        <MobileMenuIcon pathname={pathname} />
      </div>
      {
        isActive ?
          <MobileMenuWindow pathname={pathname} menuItems={menuItems} setActive={setActive} />
          :
          null
      }
    </div>
  );
}

const MobileMenuIcon: React.FC<MenuItemColor> = ({pathname}) => {
  return (   
    <svg className="IconSVG" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path fill={ColorSelector.for(pathname).foreground} d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
    </svg>
  )
}

const MobileMenuWindow: React.FunctionComponent<MenuProps & MenuHandler & MenuItemColor> = ({menuItems, setActive, pathname}) => {
  const items = menuItems.map((items) => ({...items, setActive, pathname}))
  return (
    <div className="MobileMenu">
      {
        items.map(MobileItem).concat(ContactMenuItem({mobile: true, pathname}))
      }
      <div className="MobileMenuBackground"></div>
    </div>
  );
}

const MobileItem: React.FC<MenuItem & MenuHandler & MenuItemColor> = (props, index) => {
  return (
    props.dropdown ? MobileMenuDropdownItem(props, index) : MobileMenuItem(props, index)
  )
}

const MobileMenuItem: React.FC<MenuItem & MenuHandler & MenuItemColor> = ({path, name, setActive, pathname}, index) => {
  return (
    <Link 
      key={index} 
      to={path} 
      className={index === 0 ? "Mobile MenuItemLink first" : "Mobile MenuItemLink"}
      onClick={() => setActive(false)}
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <h3 className={'Mobile MenuItem'}>
        {name}
      </h3>
    </Link>
  );
}

const MobileMenuDropdownItem: React.FC<MenuItem & MenuHandler & MenuItemColor> = ({name, dropdown, pathname, setActive}, index) => {
  const [isDropdownActive, setDropdownActive] = useState(false)
  return (
    <a
      key={name}
      className={index === 0 ? "Mobile MenuItemLink first" : "Mobile MenuItemLink"}
      onClick={() => setDropdownActive(!isDropdownActive)}
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <h3 className={'Mobile MenuItem'}>{name}</h3>
      {
        isDropdownActive && dropdown ? 
          <DropdownMenu 
            mobile={true}
            pathname={pathname} 
            dropdown={dropdown} 
            setActive={setActive}/>
          :
          null
      }
    </a>
  );
}

export default MobileMenu;