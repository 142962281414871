import React from "react";
import VideoPage from '../../../components/VideoPage';
import { RouteViewComponent, routeView } from "../..";

const DetenidosDesarecidos1: RouteViewComponent<{}> = () => {
  const videoSources = [
    {
      src: `${process.env.PUBLIC_URL}/assets/videos/detenidxs-desaparecidxs.mp4`,
      type: "video/mp4"
    }
  ]

  return (
    <iframe src="https://player.vimeo.com/video/533299516" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
  );
}

DetenidosDesarecidos1.displayName = "DetenidosDesarecidos1"

export default routeView(DetenidosDesarecidos1)