import React, { useState } from 'react'
import './styles.css'
import { RouteViewComponent, routeView } from "..";
import ColorSelector from '../../lib/colorSelector';
import homePageVideoSources from './data';
import Button from './button';
import PageControl from './pageControl';

export const Home: RouteViewComponent<{}> = (props) => {
  const[activeIndex, setActiveIndex] = useState(0)

  const incrementIndex = () => {
    activeIndex < (homePageVideoSources.length - 1) ? setActiveIndex(activeIndex + 1) : setActiveIndex(0)
  }
  
  const decrementIndex = () => {
    activeIndex === 0 ? setActiveIndex(homePageVideoSources.length - 1) : setActiveIndex(activeIndex - 1)
  }

  return (
    <>
    <figure style={{width: "80%", height: "80%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5em"}}>
      <img style={{width: "100%", height: "100%", objectFit: "contain"}} src="assets/home-image.jpeg"/>
    </figure>
      
    </>
  );
}

Home.displayName = 'Home'

export default routeView(Home)