import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import "./styles.css"
import ColorSelector from "../../lib/colorSelector";
import { withRouter, RouteComponentProps } from "react-router";

interface VideoSourceProps {
  src: string;
  type: string;
}

interface VideoPageProps {
  name: string;
  sources: VideoSourceProps[]
}

const VideoPage: React.FC<VideoPageProps & RouteComponentProps> = ({location, name, sources}) => {
  const [muted, setMuted] = useState(true)

  return (
    <div className={`VideoPage ${name}`}>
      <VideoWindow muted={muted} sources={sources} />
      <AudioToggleButton pathname={location.pathname} muted={muted} onClickListener={() => setMuted(!muted)} />
    </div>
  );
}

interface VideoPlayerProps {
  muted: boolean;
  sources: VideoSourceProps[];
}

const VideoSource: React.FC<VideoSourceProps> = ({src, type}, index) => {
  return <source key={index} src={src} type={type} />;
}

const VideoWindow: React.FC<VideoPlayerProps> = ({muted, sources}) => {
  return (
    <video 
      className="video"
      autoPlay 
      playsInline 
      loop 
      muted={muted} 
    >
      {sources.map(VideoSource)}
    </video>
  );
}

interface AudioToggleProps {
  pathname: string
  muted: boolean
  onClickListener: () => void
}

const AudioToggleButton: React.FC<AudioToggleProps> = ({pathname, muted, onClickListener}) => {
  const iconName = muted ? 'play' : 'pause'

  return (
    <Icon.Group 
      className='audioToggleButton'
      onClick={onClickListener}
      style={{color: ColorSelector.for(pathname).foreground}}
    >
      <Icon 
        className='audioToggleIcon'
        size='big'
        name={iconName} 
        />
    </Icon.Group>
  );
}

export default withRouter(VideoPage)