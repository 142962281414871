import React from 'react';
import './styles.css'
import { RouteViewComponent, routeView } from '..';
import data from "./data"
import CuratorialItem from './item';

const Curatorial: RouteViewComponent<{}> = () => {
  return (
    <>
      {data.map(CuratorialItem)}
    </>
  );
}

Curatorial.displayName = 'Curatorial'

export default routeView(Curatorial)