import React from "react";
import VideoPage from '../../../components/VideoPage';
import { RouteViewComponent, routeView } from "../..";

const SonicWeavings: RouteViewComponent<{}> = () => {
  const videoSources = [
    {
      src: `${process.env.PUBLIC_URL}/assets/videos/sonic-weavings.mp4`,
      type: "video/mp4"
    }
  ]

  return (
    <VideoPage 
      name="SonicWeavings"
      sources={videoSources}
    />
  );
}

SonicWeavings.displayName = "SonicWeavings"

export default routeView(SonicWeavings)